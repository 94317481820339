/* eslint-disable max-classes-per-file */
import { AnalyticsGatewayInterface } from "./AnalyticsGatewayInterface";

export enum NewsletterSignupResult {
  Success,
  Failure,
}

export interface NewsletterSignup {
  signup: (email: string, source: string) => Promise<NewsletterSignupResult>;
}

export interface NewsletterSignupAdapter {
  signup: (email: string, source: string) => Promise<NewsletterSignupResult>;
}

export class HubspotNewsletterSignupAdapter implements NewsletterSignupAdapter {
  analyticsGateway: AnalyticsGatewayInterface;

  formId: string;

  portalId: string;

  constructor(
    analyticsGateway: AnalyticsGatewayInterface,
    formId: string,
    portalId: string
  ) {
    this.analyticsGateway = analyticsGateway;
    this.formId = formId;
    this.portalId = portalId;
  }

  url(): string {
    return `https://api.hsforms.com/submissions/v3/integration/submit/${this.portalId}/${this.formId}`;
  }

  requestBody(email: string, pageName: string): object {
    return {
      submittedAt: Date.now(),
      fields: [{ name: "email", value: email }],
      context: { pageUri: location.href, pageName },
    };
  }

  async signup(email: string, source: string): Promise<NewsletterSignupResult> {
    const response = await fetch(this.url(), {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: { "Content-Type": "application/json" },
      referrerPolicy: "no-referrer",
      body: JSON.stringify(this.requestBody(email, source)),
    });
    if (response.ok) {
      this.analyticsGateway.trackEvent("Newsletter Signup Submitted");
      return NewsletterSignupResult.Success;
    }
    this.analyticsGateway.trackEvent("Newsletter Signup Error");
    return NewsletterSignupResult.Failure;
  }
}

export class NewsletterSignupInteractor implements NewsletterSignup {
  newsletterSignupAdapter: NewsletterSignupAdapter;

  constructor(newsletterSignupAdapter: NewsletterSignupAdapter) {
    this.newsletterSignupAdapter = newsletterSignupAdapter;
  }

  signup(email: string, source: string) {
    return this.newsletterSignupAdapter.signup(email, source);
  }
}
