import { Col, Container, Row } from "react-grid-system";
import { TrackedLink } from "@components/analytics/TrackedLink";
import { NewsletterContainerDark } from "@components/newsletter/NewsletterContainerDark";
import { VideoBox } from "@components/doc/VideoBox";
import Hero from "@components/layout/Hero";
import UserExplorationBar from "@components/homepage/UserExplorationBar";
import HowProductsWorkStepper from "@components/homepage/HowProductsWorkStepper";
import theme from "@config/theme";

const HeroBottomContent = () => (
  <>
    <div className="hero-button-container">
      <TrackedLink
        href="https://shop.blues.com/collections/blues-starter-kits"
        className="btn btnSecondary"
      >
        <span>Buy a Kit</span>
      </TrackedLink>
    </div>
    <div className="hero-user-exploration-bar">
      <UserExplorationBar />
    </div>
    <style jsx>{`
      .hero-button-container,
      .hero-user-exploration-bar {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .hero-user-exploration-bar {
        margin: 7rem auto 0;
        padding: 0 2rem 2.5rem 2rem;
      }

      .hero-button-container :global(.btn.btnSecondary) {
        color: ${theme.colors.white};
        border: 2px solid ${theme.colors.white};
        margin-top: 0;
        width: 150px;
        text-align: center;
        z-index: 1;
      }

      .hero-button-container :global(.btn.btnSecondary:hover) {
        mix-blend-mode: screen;
        color: ${theme.colors.black};
        background-color: ${theme.colors.white};
      }

      // media queries to make the glassmorphic button bar shrink along with the buttons
      // as they wrap onto two+ lines as the viewport size decreases
      @media screen and (max-width: 1226px) {
        .hero-user-exploration-bar {
          max-width: 766px;
        }
      }

      @media screen and (max-width: 766px) {
        .hero-user-exploration-bar {
          max-width: 536px;
        }
      }

      @media screen and (max-width: 536px) {
        .hero-user-exploration-bar {
          max-width: 296px;
        }
      }

      // md to lg screens 768px to 1280px wide
      @media screen and (min-width: ${theme.breakpoints
          .betweenSmMd}px) and (max-width: ${theme.breakpoints.lg}px) {
        .hero-button-container :global(.btn.btnSecondary) {
          margin-top: 1rem;
        }
      }

      @media screen and (max-width: ${theme.breakpoints.noReplMobile}px) {
        .hero-user-exploration-bar {
          margin: 5rem auto 0;
        }

        .hero-button-container :global(.btn.btnSecondary) {
          margin-top: 2rem;
        }
      }
    `}</style>
  </>
);

const HomePage = () => (
  <>
    <Hero
      title="Blues Developer Center"
      subtitle="We provide wireless products that work, so you can build connected, intelligent solutions."
    >
      <HeroBottomContent />
    </Hero>

    <div className="how-products-work-container">
      <HowProductsWorkStepper />
    </div>

    <Container>
      <div className="home-page-lower-section">
        <Row>
          <Col md={12} lg={6}>
            <div className="home-page-video-container">
              <VideoBox
                heightPx={334}
                widthPx={594}
                src="/_media/learn-blues-in-3-minutes.compressed.HSH-4fb601.mp4"
                poster="/images/home/learn-blues-in-3-minutes.video-poster.webp"
              />
            </div>
          </Col>
          <Col md={12} lg={6}>
            <div className="home-page-benefits-container">
              <h2 className="home-page-benefits-title">
                Learn Blues in 3 Minutes
              </h2>
              <ul>
                <li>500 MB of cellular data included</li>
                <li>JSON-based APIs—no AT commands</li>
                <li>Send data from any device to any cloud</li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
    <NewsletterContainerDark pageSource="Landing Page" />
    <style jsx>{`
      .how-products-work-container {
        width: 100vw;
      }

      .home-page-lower-section {
        padding: 7rem 0;
      }

      .home-page-video-container,
      .home-page-benefits-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .home-page-benefits-container {
        flex-direction: column;
        align-items: flex-start;
        margin: auto;
        height: 100%;
        width: fit-content;
      }

      .home-page-benefits-title {
        font-size: ${theme.fonts.size.px24};
      }

      .home-page-benefits-container li {
        font-size: ${theme.fonts.size.px18};
        margin: 0 0 1.5rem 0;
      }

      // md to lg screens 768px to 1280px wide
      @media screen and (min-width: ${theme.breakpoints
          .betweenSmMd}px) and (max-width: ${theme.breakpoints.lg}px) {
        .hero-button-container :global(.btn.btnSecondary) {
          margin-top: 1rem;
        }
      }

      @media screen and (max-width: ${theme.breakpoints.md}px) {
        .home-page-lower-section {
          padding: 5rem 0;
        }

        .home-page-benefits-container {
          padding-top: 4rem;
        }
      }
    `}</style>
  </>
);

export default HomePage;
