import Head from "next/head";

type HtmlPageTitleProps = {
  title: string;
};

const HtmlPageTitle = ({ title }: HtmlPageTitleProps) => (
  <Head>
    <title>{title}</title>
  </Head>
);

export { HtmlPageTitle };
