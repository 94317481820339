import { KeyboardEvent, MouseEvent, useEffect, useState, useRef } from "react";
import { Col, Container, Row } from "react-grid-system";
import { TrackedLink } from "@components/analytics/TrackedLink";
import theme from "@config/theme";
import useInterval from "@hooks/useInterval";
import useElementOnScreen from "@hooks/useElementOnScreen";

const HowProductsWorkStepper = () => {
  const steps = [
    {
      id: "step-1",
      title: "Step 1",
      text: "Connect the Notecard to your hardware or ours",
      videoLength: 11,
      videoPath: "/_media/step1.800w.HSH-0d2413.mp4",
    },
    {
      id: "step-2",
      title: "Step 2",
      text: "Use the Notecard's firmware libraries to send your data to our secure cloud service, Notehub",
      videoLength: 8.5,
      videoPath: "/_media/step2.800w.HSH-e48c57.mp4",
    },
    {
      id: "step-3",
      title: "Step 3",
      text: "Route your data to your cloud of choice",
      videoLength: 10.5,
      videoPath: "/_media/step3.800w.HSH-572124.mp4",
    },
    {
      id: "step-4",
      title: "Step 4",
      text: "Use Notehub to manage devices, fleets of devices, and to perform host MCU firmware updates",
      videoLength: 10,
      videoPath: "/_media/step4.800w.HSH-3660c6.mp4",
    },
  ];

  const [index, setIndex] = useState(1);
  const [stepperDelay, setStepperDelay] = useState(60000); // default ms delay before stepper rotates to next step
  const [currentlyActiveStep, setCurrentlyActiveStep] = useState("1");

  const clearPrevSelectedStep = () => {
    const allCircles = document.querySelectorAll(".circle");
    const allPulses = document.querySelectorAll(".pulse");
    allCircles.forEach((circle) => {
      circle.classList.remove("active");
    });
    allPulses.forEach((pulse) => {
      pulse.classList.remove("active");
    });
  };

  const retriggerVideoFadeIn = () => {
    // oddball solution to retrigger video fade in animation each time new video is loaded. https://css-tricks.com/restart-css-animation/
    const video = document.querySelector(
      ".how-products-work-video-container video",
    ) as HTMLElement;
    video?.classList.remove("active");
    void video?.offsetWidth;
    video?.classList.add("active");
  };

  const getActiveStepId = (element: HTMLDivElement) => {
    const stepId = element.parentElement?.id?.split("-")[1];
    return stepId ? Number(stepId) : undefined;
  };

  const getActiveStepVideoLength = (stepId: number) => {
    const currentStep = steps.find((step) => step.id === `step-${stepId}`);
    // accounting for the fact that the video playback rate is slowed down to 0.5 speed
    return currentStep && currentStep.videoLength * 1000 * 2;
  };

  const activateClickedStepAndVideo = (
    event:
      | MouseEvent<HTMLDivElement, globalThis.MouseEvent>
      | KeyboardEvent<HTMLDivElement>,
  ) => {
    clearPrevSelectedStep();
    let target;
    if (event.type === "click") {
      target = event.target as HTMLDivElement;
    } else if (
      event.type === "keydown" &&
      event.target instanceof HTMLElement &&
      event.target.children
    ) {
      target = event.target.children[0] as HTMLDivElement;
    }
    if (target) {
      target.classList.add("active");
      const activeStepId = getActiveStepId(target);
      if (activeStepId) {
        const nextSibling = target.nextElementSibling;
        if (nextSibling) {
          nextSibling.classList.add("active");
        }
        const activeStepVideoLength = getActiveStepVideoLength(activeStepId);
        if (activeStepVideoLength) {
          setStepperDelay(activeStepVideoLength);
        }
        setCurrentlyActiveStep(`${activeStepId}`);
        setIndex(Number(activeStepId));
        retriggerVideoFadeIn();
      }
    }
  };

  const selectNextStepAndVideo = () => {
    clearPrevSelectedStep();
    const selectedStep = document.querySelector(`#step-${[index]}`);
    selectedStep?.firstElementChild?.classList.add("active");
    selectedStep?.lastElementChild?.classList.add("active");
    retriggerVideoFadeIn();
    setCurrentlyActiveStep(`${index}`);
    const activeStepVideoLength = getActiveStepVideoLength(index);
    if (activeStepVideoLength) {
      setStepperDelay(activeStepVideoLength);
    }

    if (index === steps.length) {
      setIndex(1);
    } else {
      setIndex(index + 1);
    }
  };

  const videoRef = useRef<HTMLVideoElement>(null);
  const stepperRef = useRef<HTMLDivElement>(null);
  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.8,
  };

  const handlePlayVideo = async () => {
    if (videoRef.current) {
      try {
        await videoRef.current.play();
      } catch (error) {
        // don't worry about this error if browser tries to pause video to save power or load new requests
      }
    }
  };

  const {
    isVisible: isStepperVisible, // is stepper-setion in viewport
    isAboveViewport: isStepperAboveViewport, // has user scrolled past stepper section
  } = useElementOnScreen(options, stepperRef);

  // get the stepper and video started in sync on initial page load once video's in view, prevent next step from being triggered if user has scrolled past video and scrolls video back into view
  // basically this runs once on page load the first time the video is scrolled into view
  useEffect(() => {
    if (isStepperVisible && !isStepperAboveViewport && videoRef.current) {
      videoRef.current.playbackRate = 0.5;
      handlePlayVideo();
      selectNextStepAndVideo();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStepperVisible]);

  // once video's in viewport, play each video as it comes into video source either by clicking on step manually or by stepper rotating automatically
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.5;
      handlePlayVideo();
    }
  }, [index, currentlyActiveStep]);

  // custom hook to rotate stepper on a setInterval
  useInterval(() => {
    selectNextStepAndVideo();
  }, stepperDelay);

  const videoWidth = 800;
  const videoHeight = 450;

  return (
    <>
      <div className="how-products-work-stepper-container">
        <Container>
          <Row>
            <Col>
              <h2 className="how-products-work-title">How Our Products Work</h2>
            </Col>
          </Row>
          <Row>
            <Col md={12} lg={5}>
              <div className="line" ref={stepperRef}>
                {steps.map((step) => (
                  <div className="step" key={step.title}>
                    <div
                      id={step.id}
                      className="circle-container"
                      role="button"
                      tabIndex={0}
                      onClick={(event) => activateClickedStepAndVideo(event)}
                      onKeyDown={(event) => {
                        if (event.key === "Enter" || event.key === " ") {
                          activateClickedStepAndVideo(event);
                        }
                      }}
                    >
                      <div className="circle" />
                      <div className="pulse" />
                    </div>
                    <div className="text-container">
                      <p>{step.title}</p>
                      <ul>
                        <li>{step.text}</li>
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
            <Col md={12} lg={7}>
              <div className="how-products-work-video-container">
                <div className="how-products-work-video">
                  <video
                    src={steps[Number(currentlyActiveStep) - 1].videoPath}
                    loop
                    muted
                    playsInline
                    width={videoWidth}
                    height={videoHeight}
                    ref={videoRef}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="how-products-work-buttons">
                <div className="kit-button">
                  <TrackedLink
                    href="https://shop.blues.com/collections/blues-starter-kits"
                    className="btn"
                  >
                    <span>Buy a Kit</span>
                  </TrackedLink>
                </div>
                <div className="get-started-button">
                  <TrackedLink
                    href="/quickstart"
                    className="btn btnSecondary dark"
                  >
                    <span>Get Started</span>
                  </TrackedLink>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <style jsx>{`
        .how-products-work-stepper-container {
          background-color: ${theme.colors.black};
          padding: 2rem 0 5rem 0;
        }

        .how-products-work-title {
          font-size: ${theme.fonts.size.px32};
          color: ${theme.colors.white};
        }

        .line {
          position: relative;
          overflow: hidden;
          padding: 1.5rem 1rem 0 1rem;
        }

        .line::after {
          content: " ";
          width: 2px;
          background: ${theme.colors.white};
          position: absolute;
          top: 50px;
          bottom: 115px;
          left: 2.6rem;
        }

        .step {
          display: flex;
          align-items: flex-start;
          min-height: 130px;
        }

        .text-container {
          color: ${theme.colors.white};
          padding: 0 0 1rem 3rem;
        }

        .text-container p {
          font-size: ${theme.fonts.size.px24};
          margin: 0;
          font-weight: 600;
        }

        .text-container ul {
          padding-left: 0;
          margin-top: 0.5rem;
          list-style: none;
        }

        .text-container li {
          font-size: ${theme.fonts.size.px18};
        }

        .circle-container {
          display: grid;
          grid-template-columns: 53px;
          grid-template-rows: 53px;
          justify-content: center;
          align-content: center;
        }

        .circle {
          background-color: ${theme.colors.white};
          height: 32px;
          width: 32px;
          border-radius: 50%;
          z-index: 2;
          grid-column: 1 / 2;
          grid-row: 1 / 2;
          margin: auto;
          cursor: pointer;
        }

        .circle.active {
          background-image: radial-gradient(
            circle at center,
            ${theme.colors.ultramarineTintLighter} 1%,
            ${theme.colors.ultramarineTint} 99%
          );
        }

        .pulse {
          display: none;
        }

        .pulse.active {
          display: block;
          border-radius: 50%;
          border: 2.5px solid ${theme.colors.ultramarineTint};
          grid-column: 1 / 2;
          grid-row: 1 / 2;
          animation: pulse 1.25s infinite;
        }

        .how-products-work-video-container {
          height: 100%;
          display: flex;
        }

        .how-products-work-video {
          margin: auto;
        }

        video {
          width: 100%;
          max-width: 800px;
          opacity: 0;
        }

        video.active {
          animation: fadeIn 1s ease-in;
          opacity: 1;
        }

        .how-products-work-buttons {
          display: flex;
          flex-direction: row;
          margin-left: 7rem;
          padding-top: 2rem;
        }

        .kit-button {
          margin-right: 1.5rem;
        }

        .kit-button :global(.btn),
        .get-started-button :global(.btn) {
          width: 150px;
          text-align: center;
        }

        @media screen and (min-width: ${theme.breakpoints
            .md}px) and (max-width: ${theme.breakpoints.lg}px) {
          .step {
            min-height: 158px;
          }

          .line::after {
            bottom: 145px;
          }
        }

        // md screens 991px and smaller
        @media screen and (max-width: ${theme.breakpoints.md}px) {
          .how-products-work-title {
            font-size: ${theme.fonts.size.px28};
          }

          .text-container p {
            font-size: ${theme.fonts.size.px24};
          }

          .how-products-work-buttons {
            margin-left: auto;
          }
        }
        // sm screens 600px and smaller
        @media screen and (max-width: ${theme.breakpoints.noReplMobile}px) {
          .text-container {
            padding: 0 0 1rem 2rem;
          }

          .how-products-work-video-container {
            padding: 2rem 1rem 0 0.5rem;
          }

          .how-products-work-video {
            margin: -4rem auto;
          }

          .how-products-work-buttons {
            justify-content: center;
          }
        }
        // smallest screens 480px and smaller
        @media screen and (max-width: ${theme.breakpoints.sm}px) {
          .how-products-work-title {
            font-size: ${theme.fonts.size.px24};
          }

          .text-container p {
            font-size: ${theme.fonts.size.px22};
          }

          .step {
            min-height: 158px;
          }

          .line::after {
            bottom: 145px;
          }

          .text-container {
            padding: 0 0 1rem 1rem;
          }

          .how-products-work-buttons {
            flex-direction: column;
            align-items: center;
          }

          .kit-button {
            margin-right: 0;
          }
        }

        @keyframes pulse {
          0% {
            transform: scale(1, 1);
            opacity: 1;
          }
          100% {
            transform: scale(1.2, 1.2);
            opacity: 0;
          }
        }

        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      `}</style>
    </>
  );
};

export default HowProductsWorkStepper;
