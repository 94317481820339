import { ImgSrc } from "@config/image";
import theme from "@config/theme";

type HeroProps = {
  title: string;
  subtitle: string;
  children?: React.ReactNode;
  size?: "compact" | "normal";
};

const Hero = ({ title, subtitle, children, size }: HeroProps) => (
  <>
    <div
      className={`hero-container ${size === "compact" && "hero-container-compact"}`}
    >
      <div className="hero-background-noise" />
      <h1 className="hero-title">{title}</h1>
      <h2 className="hero-subtitle">{subtitle}</h2>
      {children}
    </div>

    <style jsx>{`
      .hero-container {
        background: linear-gradient(
          216deg,
          ${theme.colors.ultramarineTintLighter},
          ${theme.colors.ultramarineTint},
          #317aff,
          ${theme.colors.ultramarineTint}
        );
        background-size: 800% 800%;
        animation: animateGradient 65s ease infinite;
        width: 100vw;
        margin-top: -4.5rem;
        color: ${theme.colors.white};
        position: relative;
        padding-bottom: 4.5rem;
      }

      .hero-background-noise {
        background-image: url("${ImgSrc(
          "/images/home/background-noise.webp",
        )}");
        background-size: cover;
        opacity: 0.2;
        mix-blend-mode: overlay;
        width: 100vw;
        position: absolute;
        top: 0;
        bottom: 0;
        pointer-events: none; // allow clicks to pass through to buttons below
        z-index: 1;
      }

      .hero-title {
        padding-top: 4.5rem;
        font-weight: 600;
        font-size: clamp(
          ${theme.fonts.size.px36},
          1.9rem + 1.6vw,
          ${theme.fonts.size.px56}
        ); // clamp formula generated by: https://clamp.font-size.app/
      }

      .hero-subtitle {
        font-weight: 400;
        font-size: clamp(
          ${theme.fonts.size.px24},
          1.36rem + 0.64vw,
          ${theme.fonts.size.px32}
        );
      }

      .hero-title,
      .hero-subtitle {
        text-align: center;
        padding-left: 4rem;
        padding-right: 4rem;
      }

      .hero-container-compact .hero-subtitle {
        font-size: ${theme.fonts.size.px18};
        max-width: 800px;
        margin: 0 auto;
        line-height: 1.5;
      }

      @media screen and (min-width: ${theme.breakpoints
          .betweenSmMd}px) and (max-width: ${theme.breakpoints.lg}px) {
        .hero-subtitle {
          line-height: 1.3;
        }
      }

      @media screen and (max-width: ${theme.breakpoints.md}px) {
        .hero-container {
          margin-top: -3rem;
        }
      }

      @media screen and (max-width: ${theme.breakpoints.noReplMobile}px) {
        .hero-title {
          line-height: 1.1;
        }
      }

      @media screen and (max-width: ${theme.breakpoints.sm}px) {
        .hero-title,
        .hero-subtitle {
          padding-left: 1.5rem;
          padding-right: 1.5rem;
        }
      }

      @keyframes animateGradient {
        0% {
          background-position: 0%;
        }
        50% {
          background-position: 100%;
        }
        100% {
          background-position: 0%;
        }
      }
    `}</style>
  </>
);

export default Hero;
