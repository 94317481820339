import { FormEvent, useState } from "react";

import theme from "@config/theme";
import { NewsletterSignupResult } from "@use-cases/NewsletterSignupInteractor";
import { CNewsletterSignupInteractor } from "@configured/use-cases/CNewsletterSignupInteractor";

type NewsletterSignupProps = {
  pageSource: string;
};

enum Status {
  Failure,
  NotSubmitted,
  Success,
}

type NewsletterSignupState = {
  email: string;
  status: Status;
};

const DEFAULT_NEWSLETTER_SIGNUP_STATE = {
  email: "",
  status: Status.NotSubmitted,
};

const NewsletterSignup = ({ pageSource }: NewsletterSignupProps) => {
  const [state, setState] = useState<NewsletterSignupState>(
    DEFAULT_NEWSLETTER_SIGNUP_STATE,
  );

  const shouldDisplayForm = state.status !== Status.Success;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    CNewsletterSignupInteractor.signup(state.email, pageSource).then(
      (result) => {
        if (result === NewsletterSignupResult.Success) {
          setState({
            ...state,
            email: "",
            status: Status.Success,
          });
        } else {
          setState({
            ...state,
            status: Status.Failure,
          });
        }
      },
    );
  };

  return (
    <>
      <form
        onSubmit={(event) => handleSubmit(event)}
        className="newsletter-signup"
      >
        {shouldDisplayForm && (
          <div className="newsletter-input-bar">
            <input
              value={state.email}
              onChange={(event) => {
                setState({
                  ...state,
                  email: event.target.value,
                });
              }}
              name="email"
              placeholder="Email"
              className="newsletter-input"
            />
            <button type="submit" className="btn news-button">
              Subscribe
            </button>
          </div>
        )}
      </form>
      {state.status === Status.Success && (
        <span className="success">You&apos;re signed up!</span>
      )}
      {state.status === Status.Failure && (
        <span className="error">
          There was a problem submitting your e-mail.
        </span>
      )}
      <style jsx>
        {`
          .newsletter-signup {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: fit-content;
          }

          .newsletter-input {
            border: 1px solid ${theme.colors.gray4};
            padding: 0.5rem;
            border-radius: 4px;
            margin-right: 20px;
            min-width: 200px;
            flex-grow: 0;
            width: fit-content;
          }

          p {
            margin-right: 1rem;
          }

          .news-button {
            margin-top: 0;
            font-size: ${theme.fonts.size.px16};
          }

          .newsletter-input-bar {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
          }

          :global(input) {
            font-size: ${theme.fonts.size.px16};
          }

          .error,
          .success {
            font-weight: 500;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .error {
            color: ${theme.colors.poppyRed};
            padding: 1rem 0;
            width: 100%;
          }

          .success {
            padding: 0;
            color: ${theme.colors.kellyGreen};
          }

          @media screen and (max-width: ${theme.breakpoints.betweenSmMd}px) {
            .newsletter-signup {
              width: 100%;
              justify-content: flex-start;
            }

            .newsletter-signup input {
              flex-grow: 1;
              width: 100%;
            }
          }

          @media screen and (max-width: ${theme.breakpoints.sm}px) {
            .newsletter-signup {
              flex-direction: column;
            }

            .newsletter-signup input {
              margin-right: 0;
            }

            .news-button {
              margin-top: 1rem;
            }

            .newsletter-input-bar {
              flex-direction: column;
            }
          }

          /* Handle newsletter signups in sidebars */
          :global(.sidebar .newsletter-signup) {
            margin-bottom: 1rem;
          }
          :global(.sidebar .newsletter-signup .newsletter-input-bar) {
            display: block;
            margin-top: 1rem;
          }
          :global(.sidebar .newsletter-signup .newsletter-input) {
            margin-right: 0;
          }
          :global(.sidebar .newsletter-signup .news-button) {
            margin-top: 1rem;
          }
        `}
      </style>
    </>
  );
};

export { NewsletterSignup };
