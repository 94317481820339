import { TrackedLink } from "@components/analytics/TrackedLink";
import { ImgSrc } from "@config/image";
import theme from "@config/theme";

interface HomePageCardProps {
  card: {
    title: string;
    link: string;
    img: string;
  };
}

const HomePageCard = ({ card }: HomePageCardProps) => (
  <>
    <div className="card">
      <TrackedLink href={card.link}>
        <img className="card-icon" alt="" src={ImgSrc(`${card.img}`)} />
        <h3 className="card-title">{card.title}</h3>
      </TrackedLink>
    </div>
    <style jsx>{`
      .card {
        width: 200px;
        height: 140px;
        background-color: ${theme.colors.white};
        margin: 1rem;
        border-radius: 4px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
      }

      .card :global(a) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .card:hover {
        box-shadow: 2px 7px 8px rgba(0, 0, 0, 0.3);
      }

      .card-icon {
        height: 35px;
        margin: 2rem 0 0.5rem 0;
      }

      .card-title {
        color: ${theme.colors.black};
        font-weight: 400;
        font-size: ${theme.fonts.size.px18};
      }
    `}</style>
  </>
);

export default HomePageCard;
