import Head from "next/head";

type MetaDescriptionProps = {
  description: string;
};

const MetaDescription = ({ description }: MetaDescriptionProps) => (
  <Head>
    <meta name="description" content={description} />
  </Head>
);

export { MetaDescription };
