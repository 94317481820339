import theme from "@config/theme";
import { NewsletterSignup } from "@components/newsletter/NewsletterSignup";

type NewsletterSignupProps = {
  pageSource: string;
};

const NewsletterContainerDark = ({ pageSource }: NewsletterSignupProps) => (
  <div className="newsletter-container-dark">
    <h3 className="newsletter-title-dark">Blues Developer Newsletter</h3>
    <p>The latest IoT news for developers, delivered right to your inbox.</p>
    <NewsletterSignup pageSource={pageSource} />
    <style jsx>
      {`
        .newsletter-container-dark {
          background-color: ${theme.colors.black};
          color: ${theme.colors.white};
          padding: 75px 50px;
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .newsletter-title-dark {
          font-size: ${theme.fonts.size.px24};
          margin: 0 auto 10px;
        }

        p {
          margin: 0 auto 30px;
        }
      `}
    </style>
  </div>
);

export { NewsletterContainerDark };
