import HomePageCard from "@components/homepage/HomePageCard";

const UserExplorationBar = () => {
  const cardContents = [
    {
      title: "Get Started",
      link: "/quickstart",
      img: "/images/home/started-icon.svg",
    },
    { title: "Docs", link: "/docs", img: "/images/home/docs-icon.svg" },
    {
      title: "Example Apps",
      link: "/example-apps",
      img: "/images/home/accelerators-icon.svg",
    },
    {
      title: "Datasheets",
      link: "/datasheets",
      img: "/images/home/datasheets-icon.svg",
    },
    { title: "Blog", link: "/blog", img: "/images/home/blog-icon.svg" },
  ];

  return (
    <>
      <div className="user-exploration-bar-container">
        {cardContents.map((card) => (
          <HomePageCard card={card} key={card.title} />
        ))}
      </div>
      <style jsx>{`
        .user-exploration-bar-container {
          background: rgba(
            255,
            255,
            255,
            0.15
          ); /* Light frosted glass background color */
          border-radius: 10px;
          backdrop-filter: blur(
            10px
          ); /* Apply the blur effect for the frosted glass */
          box-shadow: inset 0 3px 6px rgba(255, 255, 255, 0.5),
            0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Add a subtle shadow effect */
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          width: fit-content;
          border: 1px solid rgba(255, 255, 255, 0.18);
        }
      `}</style>
    </>
  );
};

export default UserExplorationBar;
