import { CAnalyticsGateway } from "@configured/adapters/CAnalyticsGateway";
import { Config } from "@configured/EnvironmentConfig";
import {
  HubspotNewsletterSignupAdapter,
  NewsletterSignupInteractor,
} from "@use-cases/NewsletterSignupInteractor";

export const CNewsletterSignupInteractor = new NewsletterSignupInteractor(
  new HubspotNewsletterSignupAdapter(
    CAnalyticsGateway,
    Config.hubspotNewsletterFormID,
    Config.hubspotPortalID
  )
);
