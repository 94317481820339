import { useEffect, useMemo, useState, RefObject } from "react";
import "intersection-observer";

const useElementOnScreen = (
  options: IntersectionObserverInit,
  targetRef: RefObject<Element>
) => {
  const [isVisible, setIsVisible] = useState<undefined | boolean>();
  const [isBelowViewport, setIsBelowViewport] = useState<undefined | boolean>(); // i.e. user hasn't yet scrolled to element
  const [isAboveViewport, setIsAboveViewport] = useState<undefined | boolean>(); // i.e. user has scrolled past element
  const callbackFunction = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries; // const entry = entries[0]
    if (entry.isIntersecting) {
      setIsVisible(entry.isIntersecting);
    }
    if (entry.boundingClientRect.top > 0) {
      setIsBelowViewport(true);
      setIsAboveViewport(false);
    } else {
      setIsAboveViewport(true);
      setIsBelowViewport(false);
    }
  };
  const optionsMemo = useMemo(() => options, [options]);
  useEffect(() => {
    const observer = new IntersectionObserver(callbackFunction, optionsMemo);
    const currentTarget = targetRef.current;
    if (currentTarget) observer.observe(currentTarget);

    return () => {
      if (currentTarget) observer.unobserve(currentTarget);
    };
  }, [targetRef, optionsMemo]);
  return { isVisible, isBelowViewport, isAboveViewport };
};

export default useElementOnScreen;
