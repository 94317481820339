import SiteChrome from "@chrome/SiteChrome";
import { SiteHtmlPageTitle } from "@content/en/HtmlTitle";
import { HtmlPageTitle } from "@components/HtmlPageTitle";
import { MetaDescription } from "@components/MetaDescription";
import HomePage from "@components/homepage/HomePage";

const MainPage = () => (
  <SiteChrome noPadding>
    <HtmlPageTitle title={`Home - ${SiteHtmlPageTitle}`} />
    <MetaDescription description="Welcome to blues.dev! We provide wireless products that work, so you can build connected, intelligent solutions." />
    <HomePage />
  </SiteChrome>
);

export default MainPage;
